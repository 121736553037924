import React, { Component } from "react";

class Portfolio extends Component {
  render() {
    const portfolioContainer = {
      backgroundColor: "#e8f5fe",
      padding: "3rem 1rem",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    };

    const projectContainer = {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(400px, 1fr))",
      gap: "40px",
      padding: "2rem",
      maxWidth: "1200px",
      width: "100%",
      justifyContent: "center",
    };

    const projectItem = {
      position: "relative",
      margin: "20px",
      padding: "30px",
      boxShadow: "0 12px 24px rgba(0, 0, 0, 0.2)",
      borderRadius: "15px",
      textAlign: "center",
      backgroundColor: "#ffffff",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      cursor: "pointer",
      textDecoration: "none",
      color: "inherit",
    };

    const projectImage = {
      width: "100%",
      height: "300px",
      objectFit: "cover",
      borderRadius: "10px",
    };

    const projectTitle = {
      fontSize: "2.5rem",
      fontWeight: "bold",
      margin: "15px 0",
      color: "#333",
    };

    const projectDescription = {
      color: "#555",
      fontSize: "1.2rem",
      margin: "10px 0",
      height: "auto",
      minHeight: "80px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 4,
      WebkitBoxOrient: "vertical",
    };

    const techStack = {
      backgroundColor: "#e0f7fa",
      padding: "15px",
      borderRadius: "5px",
      marginTop: "15px",
      fontSize: "1.4rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    };

    const techUsed = {
      fontWeight: "bold",
      color: "#333",
      fontSize: "1.2rem",
      marginBottom: "10px",
      textDecoration: "underline",
    };

    const techItem = {
      margin: "5px",
      backgroundColor: "#f1f1f1",
      padding: "8px 12px",
      borderRadius: "4px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      fontWeight: "600",
      fontSize: "1rem",
      display: "inline-block",
    };

    const projects = [
      {
        title: "UnleashAI",
        description:
          "An AI-powered storyteller that brings your ideas to life by generating enchanting narratives and vivid illustrations based on user prompts.",
        image: "unleashai.png",
        technologies: [
          "Next",
          "AWS SDK",
          "Amazon S3",
          "RDS PostgreSQL",
          "Tailwind CSS",
          "TypeScript",
          "Prisma",
          "OpenAI",
          "Node",
          "Radix UI",
        ],
        link: "https://unleashai.igorbachev.com/",
      },
      {
        title: "Netflix Clone",
        description:
          "A Netflix clone that fetches up-to-date movies from TMDb and offers a viewing experience that feels like real Netflix, including trailer playback for each movie.",
        image: "netflixclone.png",
        technologies: [
          "Next",
          "Typescript",
          "MUI",
          "Tailwind CSS",
          "Recoil",
          "React Player",
        ],
        link: "https://netflix-clone.igorbachev.com/",
      },
      {
        title: "Full MERN Stack Project",
        description:
          "Products tracker and expenses calculation filtering by date, price, and type. Open to creating multiple users with validation forms.",
        image: "mernstack.png",
        technologies: ["MongoDB", "Express", "React", "Node.js", "Auth"],
        link: "https://productexpenses.onrender.com",
      },
      {
        title: "HandyApp",
        description:
          "HandyApp is a user-friendly application that simplifies authentication and enables users to create and manage categories and items, similar to a to-do app. It helps users stay organized and productive with an intuitive interface. A mobile version is also available for download.",
        image: "handyapp.png",
        technologies: ["React", "React Native", "Firebase", "Expo", "MUI"],
        link: "https://handyapp.igorbachev.com",
        apkLink: "apk/handyapp.apk",
      },
      {
        title: "Facebook Image with React",
        description:
          "Facebook lookalike for posting images with Google Authentication.",
        image: "facebookclone.png",
        technologies: ["React", "OAuth 2.0", "Firebase", "Google API"],
        link: "https://facebook-eaaee.web.app/",
      },
      {
        title: "GroupChat",
        description:
          "Realtime Chat for multiple users created with React and Firebase.",
        image: "mychat.png",
        technologies: ["WebSockets", "React", "Firebase", "MUI Icons"],
        link: "https://mychat-458b6.web.app/",
      },
      {
        title: "Gmail Clone",
        description: "Gmail clone with functionality of sending emails.",
        image: "facebookclone.png",
        technologies: ["React", "Firebase", "EmailJS"],
        link: "https://fir-e6116.web.app/",
      },
    ]; //

    return (
      <section id="portfolio">
        <div style={portfolioContainer}>
          <h1
            style={{
              textAlign: "center",
              marginBottom: "2rem",
              fontSize: "2.8rem",
            }}
          >
            Check Out Some of My Works Below
          </h1>
          <div style={projectContainer}>
            {projects.map((project, index) => (
              <a
                key={index}
                href={project.link}
                target="_blank"
                rel="noopener noreferrer"
                style={projectItem}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "translateY(-5px)";
                  e.currentTarget.style.boxShadow =
                    "0 16px 32px rgba(0, 0, 0, 0.3)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "none";
                  e.currentTarget.style.boxShadow =
                    "0 12px 24px rgba(0, 0, 0, 0.2)";
                }}
              >
                <img
                  style={projectImage}
                  src={"images/portfolio/" + project.image}
                  alt={project.title}
                  onError={(e) => {
                    e.target.src = "https://via.placeholder.com/400x250";
                  }}
                />
                <h3 style={projectTitle}>{project.title}</h3>
                <p style={projectDescription}>{project.description}</p>
                <div style={techStack}>
                  <span style={techUsed}>Technologies Used:</span>
                  <div>
                    {project.technologies.map((tech, idx) => (
                      <span key={idx} style={techItem}>
                        {tech}
                      </span>
                    ))}
                  </div>
                </div>
                {project.apkLink && (
                  <a
                    href={project.apkLink}
                    download
                    style={{
                      marginTop: "15px",
                      display: "inline-block",
                      padding: "10px 15px",
                      backgroundColor: "#007BFF",
                      color: "#fff",
                      borderRadius: "5px",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Download APK
                  </a>
                )}
              </a>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
